<!-- 스폰서 컨텐츠 등록/수정 -->
<template>
  <v-container
          id="ArticleRegister"
          fluid
          tag="section">
    <v-row
            align="center"
            justify="center">
      <v-col cols="12">
        <base-material-card
                color="green">
          <template v-slot:heading>
            <h1 class="display-2 font-weight-regular">
              스폰서 컨텐츠 {{resultArticle}}
            </h1>
          </template>
          <v-card-text>
            <v-form v-model="vModel.valid"
                    ref="form">
              <v-row class="pl-5 pr-5"
                      align="center">
                <v-col cols="1" class="text-right grey--text">
                  제목
                </v-col>
                <v-col>
                  <v-text-field
                          placeholder="제목"
                          type="text"
                          name="title"
                          color="secondary"
                          v-model="vModel.data.title"
                          :rules="config.titleRules"
                          required />
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                     align="center">
                <v-col cols="1" class="text-right grey--text">
                  요약 내용
                </v-col>
                <v-col>
                  <v-text-field
                          placeholder="요약 내용"
                          type="text"
                          name="sub_title"
                          color="secondary"
                          v-model="vModel.data.sub_title"
                          :rules="config.subTitleRules"
                          required />
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                     align="center">
                <v-col cols="1" class="text-right grey--text">
                  썸네일
                </v-col>
                <!--썸네일 첨부-->
                <v-col v-if="response.routeName === 'contentsArticleRegister' || response.thumbnailVal">
                  <v-file-input
                          v-model="thumbnail_url"
                          name="thumbnail_url"
                          placeholder="파일 첨부"
                          prepend-icon="mdi-camera" />
                </v-col>
                <!--썸네일 미첨부-->
                <template v-else>
                  <v-col cols="10">
                    <v-text-field
                            placeholder="파일 첨부"
                            prepend-icon="mdi-camera"
                            type="text"
                            name="thumbnail_url"
                            color="secondary"
                            v-model="vModel.data.thumbnail_url"
                            disabled/>
                  </v-col>
                  <div @click="thumbnailClose()" style="cursor: pointer;">X</div>
                </template>
              </v-row>
              <v-row class="pl-5 pr-5"
                     align="center">
                <v-col cols="1" class="text-right grey--text">
                  태그
                </v-col>
                <v-col>
                  <v-text-field
                          placeholder="태그"
                          hint=", 로 구분해 주세요."
                          type="text"
                          name="tags"
                          color="secondary"
                          v-model="vModel.data.tags"
                          :rules="config.tagRules"
                          required />
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                     align="center">
                <v-col cols="1" class="text-right grey--text">
                  게재 기간
                </v-col>
                <v-col>
                  <v-row align="center">
                    <v-col cols="2">
                      <v-menu
                              ref="menu1"
                              v-model="datePicker.start.menu"
                              :close-on-content-click="false"
                              :return-value.sync="datePicker.start.date"
                              transition="scale-transition"
                              min-width="290px"
                              offset-y >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                                  v-model="datePicker.start.date"
                                  color="secondary"
                                  label="Start date"
                                  name="start"
                                  prepend-icon="mdi-calendar-outline"
                                  readonly
                                  v-on="on" />
                        </template>

                        <v-date-picker
                                v-model="datePicker.start.date"
                                color="secondary"
                                landscape
                                scrollable >
                          <v-spacer />
                          <v-btn
                                  color="secondary"
                                  @click="datePicker.start.menu = false" >
                            Cancel
                          </v-btn>

                          <v-btn
                                  color="secondary"
                                  @click="$refs.menu1.save(datePicker.start.date)" >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <span>-</span>
                    <v-col cols="2">
                      <v-menu
                              ref="menu2"
                              v-model="datePicker.end.menu"
                              :close-on-content-click="false"
                              :return-value.sync="datePicker.end.date"
                              transition="scale-transition"
                              min-width="290px"
                              offset-y >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                                  v-model="datePicker.end.date"
                                  color="secondary"
                                  label="End date"
                                  name="end"
                                  prepend-icon="mdi-calendar-outline"
                                  readonly
                                  v-on="on" />
                        </template>

                        <v-date-picker
                                v-model="datePicker.end.date"
                                color="secondary"
                                landscape
                                scrollable >
                          <v-spacer />
                          <v-btn
                                  color="secondary"
                                  @click="datePicker.end.menu = false" >
                            Cancel
                          </v-btn>

                          <v-btn
                                  color="secondary"
                                  @click="$refs.menu2.save(datePicker.end.date)" >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                     align="center">
                <v-col cols="1" class="text-right grey--text">
                  블록 번호
                </v-col>
                <v-col>
                  <v-text-field
                          placeholder="블록 번호"
                          type="number"
                          name="block_no"
                          color="secondary"
                          v-model="vModel.data.block_no"
                          :rules="config.blockRules"
                          required />
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                     align="center">
                <v-col cols="1" class="text-right grey--text">
                  landing URL
                </v-col>
                <v-col>
                  <v-text-field
                          placeholder="landing URL"
                          type="text"
                          name="landing_url"
                          color="secondary"
                          v-model="vModel.data.landing_url" />
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5"
                     align="center">
                <v-col cols="12">
                  <vue-editor id="editor"
                              v-model="vModel.data.contents"
                              :editorToolbar="customToolbar"
                              useCustomImageHandler
                              @text-change="editorChange"
                              @image-added="handleImageAdded"></vue-editor>
                </v-col>
              </v-row>
            </v-form>
            <v-row justify="center">
              <v-btn color="default"
                     @click="beforePage()">
                취소
              </v-btn>
              <template v-if="['contentsArticleView', 'ArticleView'].includes(this.$route.name)">
                <v-btn :disabled="!vModel.valid"
                       color="warning"
                       @click="adsRegister('edit')">
                  수정
                </v-btn>
              </template>
              <template v-else-if="['contentsArticleRegister', 'ArticleRegister'].includes(this.$route.name)">
                <v-btn :disabled="!vModel.valid"
                       color="warning"
                       @click="adsRegister('create')">
                  등록
                </v-btn>
              </template>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
      <base-material-snackbar
              v-model="vModel.snackbar"
              :type="vModel.snackbarType"
              v-bind="{ 'top': true, 'right': true }">
          <span style="display:block;width:300px;margin:0;">{{vModel.errorMessage}}</span>
      </base-material-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { VueEditor } from 'vue2-editor'
import { common } from '../../mixins/common.js'
import { editorSet } from '../../mixins/editorSet.js'

export default {
  components: {
    VueEditor
  },
  mixins: [ common, editorSet ],
  created () {
    this.refresh()
  },
  data () {
    return {
      customToolbar: [
        [{ 'font': [] }],
        [{ 'header': [ false, 1, 2, 3, 4, 5, 6, ] }],
        // [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
        [{ 'header': 1 }, { 'header': 2 }],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'color': [] }, { 'background': [] }],
        ['link', 'image'],
        // ['link', 'image', 'video', 'formula'],
        [{ 'direction': 'rtl' }],
        // ['clean'],
      ],
      response: {
        routeName: this.$route.name,
        media: null,
        view: null,
        thumbnailVal: true,
        adsEdit: null,
        adsCreate: null,
        image: [],
      },
      vModel: {
        snackbar: false,
        snackbarType: 'warning',
        errorMessage: null,
        valid: true,
        contents: null,
        data: {
          writer: JSON.parse(localStorage.getItem('cdpUser')).name,
          title: null,
          sub_title: null,
          contents: '',
          tags: null,
          thumbnail_url: null,
          landing_url: null,
        }
      },
      thumbnail_url: null,
      datePicker: {
        start: {
          date: '',
          menu: false
        },
        end: {
          date: '',
          menu: false
        }
      },
      config: {
        titleRules: [ v => !!v || '제목을 입력하세요.' ],
        subTitleRules: [ v => !!v || '요약 내용을 입력하세요.' ],
        tagRules: [ v => !!v || '태그를 ,로 구분하여 입력하세요.' ],
        blockRules: [ v => !!v || '블록 번호를 입력하세요.' ],
      }
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    /* 페이지 로드시 */
    refresh () {
      if (['contentsArticleView', 'ArticleView'].includes(this.$route.name)) {
        /* 스폰서컨텐츠 수정 */
        this.articleView()
      }
      if (['ArticleView', 'ArticleRegister'].includes(this.$route.name)) {
        /* cp */
        this.mediaList()
      }
    },
    /* 등록된 썸네일 지우기 */
    thumbnailClose () {
      this.vModel.data.thumbnail_url = null
      this.response.thumbnailVal = true
    },
    /* 썸네일 등록시 저장 */
    thumbnailFile () {
      let formData = new FormData()
      formData.append('upload', this.thumbnail_url)
      this.xhttp({
        url: '/article/file-upload',
        method: 'post',
        data: formData
      }).then((response) => {
        if (response.status === 200) {
          this.vModel.data.thumbnail_url = response.data.link
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 등록,수정 시 태그 변환 */
    tagChange (val) {
      this.vModel.contents = this.editorTagChange(val)
      if (val === 'create') {
        /* 등록 */
        this.putArticle()
      } else {
        /* 수정 */
        this.adsEdit()
      }
    },
    /* cp가 매체 가져오기 */
    mediaList () {
      let send = {
        orderby: 'created',
        direction: 'desc'
      }
      this.xhttp({
        url: '/media',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.media = response.data.data.media
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 스폰서컨텐츠 정보 가져오기 */
    articleView () {
      this.xhttp({
        url: '/article/' + this.$route.params.article_id,
        method: 'get',
        params: null
      }).then((response) => {
        if (response.status === 200) {
          this.response.view = response.data.data
          this.vModel.data = this.response.view.data[0]
          this.vModel.data.thumbnail_url !== 'null' ? this.response.thumbnailVal = false : this.response.thumbnailVal = true
          if (this.vModel.data.start_date) {
            this.datePicker.start.date = this.$moment(this.vModel.data.start_date.slice(0, 10)).format('YYYY-MM-DD')
          }
          if (this.vModel.data.end_date) {
            this.datePicker.end.date = this.$moment(this.vModel.data.end_date.slice(0, 10)).format('YYYY-MM-DD')
          }
          /* 에디터에 들어갈 내용 태그 변경 */
          let editorContent = this.editorSaveTagChange(this.response.view.data[0].contents)
          this.vModel.data.contents = editorContent
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 등록, 수정 버튼 클릭시 */
    adsRegister (val) {
      if (this.$refs.form.validate()) {
        let message = '등록 하시겠습니까?'
        if (val === 'edit') {
          message = '수정 하시겠습니까?'
        }
        if (confirm(message)) {
          /* 에디터 태그 변경 */
          this.tagChange(val)
        }
      }
    },
    /* 스폰서 컨텐츠 수정 */
    adsEdit () {
      let send = this.vModel.data
      send.contents = this.vModel.contents
      this.xhttp({
        url: '/article/' + this.$route.params.article_id,
        method: 'put',
        data: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.adsEdit = response.data.data
          this.snack('success', '수정 완료되었습니다.')
          this.beforePage()
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 스폰서 컨텐츠 등록 */
    putArticle () {
      let send = this.vModel.data
      send.contents = this.vModel.contents
      let media
      if (this.$route.name === 'ArticleRegister') {
        /* cp */
        media = this.response.media[0].key
      } else {
        /* admin */
        media = this.$route.params.media_id
      }
      this.xhttp({
        url: '/' + media + '/article',
        method: 'post',
        data: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.adsCreate = response.data.data
          this.snack('success', '등록 완료되었습니다.')
          this.beforePage()
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    }
  },
  watch: {
    /* 기간 설정 */
    datePicker: {
      handler (val) {
        if (val.start.date) {
          this.vModel.data.start_date = val.start.date
        }
        if (val.end.date) {
          this.vModel.data.end_date = val.end.date
        }
      },
      deep: true
    },
    /* 썸네일 등록시 */
    thumbnail_url: function (val) {
      if (val) {
        this.thumbnailFile()
      }
    }
  },
  computed: {
    resultArticle () {
      if (['contentsArticleView', 'ArticleView'].includes(this.$route.name)) {
        return '수정'
      } else {
        return '등록'
      }
    }
  }
}
</script>

<style lang="scss">
  .ck-editor__editable_inline {
    height: 584px !important;
    min-height: auto;

    & > p {
      margin: 0;
      font-size: 1em;
      line-height: 1.6em;
      padding-top: 0.2em;
    }
  }
  .ck.ck-dropdown .ck-dropdown__arrow {
    z-index: auto;
  }
  #editor{
    min-height:400px
  }
</style>
